import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function EditBrand() {
  const [flow, setFlow] = useState([]);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const getBrandbyId = () => {
    axios
      .get(`${BaseUrl}/single_flow/${id}`, { headers })
      .then((res) => {
        setFlow(res.data.data[0]);

        // setFlow(res.data.data[0].flow._id);
        setName(res.data.data[0].name);

        // setFile(res.data.data[0].image);
        // console.log(file);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBrandbyId();
  }, [id]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    const formData = new FormData();
    formData.append("name", name);

    formData.append("_id", id);

    axios
      .put(`${BaseUrl}/flows`, formData, { headers })
      .then((res) => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success("Data updated successfully");
          setOpen(false);
          navigate("/flowlist");
        }
      })
      .catch((error) => {
        console.log("Error updating data:", error);
        toast.error("Data Not updated ");
        setOpen(false);
      });
  };

  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3">Update Flow</h4>
                <div className="">
                  <Form onSubmit={handleFormSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Update Flow
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(EditBrand);
