import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";
import FlowComponent from "./FlowComponent";
import { BaseUrl, token } from "../../utils/Token";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function AddFlow() {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const naivgate = useNavigate();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleFormSubmit = async (e) => {
    setOpen(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BaseUrl}/flows`,
        { name },
        { headers }
      );

      console.log("Response:", response);
      if (response.data.status == "OK") {
        toast.success("Data Submited Successfully");
        setOpen(false);
        naivgate("/flowlist");
      }
      // Handle the response as needed
    } catch (error) {
      console.error("Error:", error);
      toast.error("Data Not Submited ");
      setOpen(false);
      // Handle the error as needed
    }
  };

  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3"> Add Flow</h4>
                <div className="">
                  <Form onSubmit={handleFormSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name <span className="text-danger">*</span>
                          </label>
                          {/* <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          /> */}
                          <FlowComponent
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Add Flow
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(AddFlow);
