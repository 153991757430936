import React, { useEffect, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import { Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import axios from "axios";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Brand() {
  const [name, setName] = useState("");

  const [flow, setFlow] = useState("");
  const [file, setFile] = useState(null);
  const [is_trademark, setis_trademark] = useState(false)

  const [flowApi, setFlowApi] = useState([]);
  const [open, setOpen] = useState(false);
  const [trademarkfile, settrademarkfile] = useState(null)

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const naivgate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);

    const formData = new FormData();
    formData.append("name", name);

    formData.append("flow", flow);

    formData.append("file", file);
    formData.append("business_documents", trademarkfile)
    formData.append("is_trademark", is_trademark)


    axios
      .post(`${BaseUrl}/brands`, formData, { headers })
      .then((response) => {
        // Handle successful response
        console.log(response.data);
        if (response.data.status == "OK") {
          toast.success("Data Submited Successfully");
          setOpen(false);
          naivgate("/brandlist");
        }
      })
      .catch((error) => {

        console.error(error);
        toast.error("Data not Submitted");
        setOpen(false);
      });
  };

  const FlowgetApi = () => {
    axios.get(`${BaseUrl}/flows`, { headers }).then((res) => {
      setFlowApi(res.data.data);
    });
    console.log(flowApi);
    // console.log(Brand[3].parent_Brand.name);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  useEffect(() => {
    FlowgetApi();
  }, []);

  const handletrademark = (e) => {
    let selectedfile = e.target.files[0]
    settrademarkfile(selectedfile)
  }

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white box">
                <h4 className="px-3 py-3"> Add Brand</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Name
                          </label>
                          <input
                            type="text"
                            required
                            name="name"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Flow
                          </label>

                          <select
                            className="form-select"
                            name="flow"
                            aria-label="Default select example"
                            value={flow}
                            onChange={(e) => setFlow(e.target.value)}
                          >
                            <option selected>Select a Flow</option>
                            {flowApi.map((item) => {
                              return (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            onChange={handleFileChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">You Have Trademark ? </label>
                          <select
                            name=""
                            value={is_trademark}
                            onChange={(e) => setis_trademark(e.target.value)}
                            className="form-control form-select"
                            id="">
                            <option value="">--Select--</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                      {is_trademark === "true" && (
                        <Col md="6">
                          <FormGroup className="d-flex w-100 gap-3 align-items-center">
                            <div className="w-100">
                              <label htmlFor="" className="fw-bold">Trademark Certificate</label>
                              <input
                                type="file"
                                name="file"
                                className="form-control w-100"
                                onChange={handletrademark}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                      <Col md="12">
                        <div className="">
                          <button className="btn bg-primary text-white">
                            Add Brand
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>
    </>
  );
}

export default adminLayout(Brand);
